<template>
  <div>
    <app-header @getHeight="getHeight"></app-header>
    <div style="background-color: #fff">
      <div class="max_width min_width">
        <div
          class="flex align_center padtb_20"
          :style="{ 'margin-top': offsetHeight + 'px' }"
        >
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <!-- <el-breadcrumb-item class="d8f">政策法规</el-breadcrumb-item> -->
            <el-breadcrumb-item class="" style="color: #ff6618"
              >需方市场</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div style="background-color: #fff">
      <div class="max_width min_width">
        <section class="bgfff">
          <div
            style="
              width: 1480px;
              padding: 30px;
              background-color: rgb(255, 221, 204);

              border-radius: 5px;
            "
          >
            <div>
              <div class="flex align_start" style="padding: 10px 0px">
                <div class="fnf size_15 weight marr_30 text_nowrap">
                  需求类型：
                </div>
                <div class="text_line1" style="width: calc(100% - 75px)">
                  <span
                    class="fnf weight size_15 marr_30 laws_font"
                    :class="{ laws_tag: patterns == '' }"
                    @click="setTag('patterns')"
                    >全部</span
                  >
                  <span
                    class="fnf size_15 marr_30 laws_font"
                    v-for="(item, index) in pattern"
                    :key="index"
                    :class="{ laws_tag: patterns == item.id }"
                    @click="setTag('patterns', item.id)"
                    >{{ item.name }}</span
                  >
                </div>
              </div>
            </div>
            <!-- 搜索框 -->

            <div
              class="flex align_center"
              style="justify-content: space-between"
            >
              <div class="flex align_center mart_20">
                <div class="fnf size_15 weight text_nowrap">关键词搜索：</div>
                <div class="width_50 sear_search">
                  <el-input @keyup.enter.native="search()"
                    placeholder="请输入关键词"
                    style="width: 500px"
                    v-model="keyword"
                  >
                    <i
                      slot="suffix"
                      class="el-icon-search"
                      @click="search()"
                    ></i>
                  </el-input>
                </div>
              </div>

              <!-- <div
                style="
                  width: 130px;
                  height: 40px;
                  background: #ff6417;
                  border-radius: 6px;
                  line-height: 40px;
                  text-align: center;
                  color: #ffffff;
                "
              >
                发布需求
              </div> -->

              <el-dropdown @command="golaw" placement="top-end">
                <div class="bigbox">
                  <!-- 验证 -->
                  <div
                    @click="authentication(releasetext)"
                    style="
                      width: 130px;
                      height: 40px;
                      background: #ff6417;
                      border-radius: 6px;
                      line-height: 40px;
                      text-align: center;
                      color: #ffffff;
                    "
                  >
                    <!-- <div
                    @click="goreleasedemand(releasetext)"
                    style="
                      width: 130px;
                      height: 40px;
                      background: #ff6417;
                      border-radius: 6px;
                      line-height: 40px;
                      text-align: center;
                      color: #ffffff;
                    "
                  > -->
                    {{ releasetext }} <i class="el-icon-caret-bottom"></i>
                  </div>
                </div>

                <el-dropdown-menu slot="dropdown" class="policy">
                  <!-- <el-dropdown-item
                      v-for="item in zhengcelist"
                      :key="item.id"
                      command=item.id
                      >{{ item.title }}</el-dropdown-item
                    > -->
                  <div class="down">
                    <el-dropdown-item
                      :command="item.name"
                      v-for="(item, index) in pattern"
                      :key="index"
                      >{{ item.name }}</el-dropdown-item
                    >
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- <div style="background-color: #fff; padding: 30px 0px">
      <div class="max_width min_width">
        <section class="bgfff">
          <div
            style="background-color: #fff; border-bottom: 1px solid #e8e8e8"
            v-for="(item, index) in selectorder"
            :key="index"
            @click="look(item)"
          >
            <div class="flex jus_content" style="align-items: center">
              <div style="flex: 1">
                <div class="size_18 servehove" style="padding: 10px 0px">
                  {{ item.demand_name }}
                </div>
                <div class="flex size_14" style="margin-bottom: 20px">
                  <div style="color: #333333">
                    需求类型：{{ item.type_name }}
                  </div>
                  <div class="marl_30" style="color: #808080">
                    发布时间：{{ item.create_time }}
                  </div>
                </div>
              </div>
              <div
                style="
                  width: 130px;
                  height: 40px;
                  border-radius: 6px;
                  line-height: 40px;
                  text-align: center;
                  color: #ff6417;
                  background: #fff3eb;
                "
              >
                查看详情
              </div>
            </div>
          </div>
        </section>
      </div>
    </div> -->
    <div  class="flex align_center min_width max_width"
                style="background: #FFFBF2;border-radius: 10px;padding: 30px ;    margin-top: 30px"
                v-if="selectorder.length === 0"
                >
               暂无内容
              </div>
    <div class="flex align_center min_width max_width" style="flex-wrap: wrap; justify-content: space-between;"  v-else >
    

              <div
                style="width: 710px;background: #FFFBF2;border-radius: 10px;padding: 30px ;    margin-top: 30px"
                v-for="(item, index) in selectorder" :key="item.id">
                <div class="size_24 bold" style="padding-bottom:20px">{{ item.demand_name }}</div>
                <div class="flex size_20" style="padding-bottom:20px; justify-content: space-between;">
                  <span>需求类型：{{ item.type_name }}</span>
                  <span>{{ item.create_time }}</span>
                </div>
                <div class="flex size_20" style="padding-bottom:20px; justify-content: space-between;">
                  <span>企业名称：{{ item.enterprise_name }}</span>
                  <div style="color: #FF6417;" @click="look(item)">查看详情>></div>
                </div>
              </div>
            </div>

    <div>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paginations.page_index"
        :page-sizes="paginations.page_sizes"
        :page-size="paginations.page_size"
        :layout="paginations.layout"
        :total="paginations.total"
      >
      </el-pagination>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
export default {
  data() {
    return {
      offsetHeight: 0,
      keyword: "",
      num: "",
      allselectorder: [],
      category: "", // 栏目类别
      selectorder: "",
      releasetext: "",
      TableData: [],
      total: "",
      pattern: [
        { id: 1, name: "采购需求" },
        { id: 2, name: "融资需求" },
        
        { id: 3, name: "服务需求" },
        { id: 4, name: "物流需求" },
        { id: 5, name: "人才需求" },
        { id: 6, name: "其他需求" },
      ],
      patterns: "", //服务模式
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
    };
  },

  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
  created() {
    this.marketlist();
    // console.log(this.pattern[0].name, "pattern[0].name");
    this.releasetext = this.pattern[0].name;
  },
  methods: {
    search() {
      console.log("789456");
      this.marketlist();
    },
    look(item) {
      console.log(item);
      switch (item.type_name) {
        case "采购需求":
          this.$router.push({ path: "/demandmessage", query: { id: item.id } });
          break;
        case "融资需求":
          this.$router.push({
            path: "/financingmessage",
            query: { id: item.id },
          });
          break;
        case "人才需求":
          this.$router.push({
            path: "/talentsmessage",
            query: { id: item.id },
          });
          break;
        case "服务需求":
          this.$router.push({
            path: "/servemessage",
            query: { id: item.id },
          });
          break;
        case "物流需求":
          this.$router.push({
            path: "/logisticsmessage",
            query: { id: item.id },
          });
          break;
        case "其他需求":
          this.$router.push({ path: "/orthermessage", query: { id: item.id } });
          break;
      }
      // this.$router.push({ path: "/demandmessage", query: { id: item.id } });
    },
    // 获取需方列表
    marketlist(item) {
      let data = {
        token: localStorage.eleToken,
        keyword: this.keyword,
        page: item ? item : this.paginations.page_index,
        limit: this.paginations.page_size,
        type: this.patterns,
      };
      console.log(data, "data");
      this.$post("demand_lst_index", data).then((res) => {
        console.log(res.result, "res.result");
        this.allselectorder = res.result;
        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          console.log("111");
          this.paginations.total = res.count;
        }

        //设置默认的分页数据
        if (this.allselectorder.length != 0) {
          this.selectorder = this.allselectorder.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.selectorder = [];
        }
        console.log(this.selectorder, "this.selectorder");
      });
    },

    setTag(name, id = "") {
      this[name] = id;
      this.paginations.page_index = 1;
      console.log("789");
      this.policyList = [];
      this.marketlist();
      //   this.selectlist();
    },
    golaw(item) {
      console.log(item, "item");
      switch (item) {
        case "采购需求":
          this.releasetext = item;
          break;
        case "融资需求":
          this.releasetext = item;
          break;
        case "物流需求":
          this.releasetext = item;
          break;
        case "服务需求":
          this.releasetext = item;
          break;
        case "人才需求":
          this.releasetext = item;
          break;
        case "其他需求":
          this.releasetext = item;
          break;
      }
    },
    mechanism_status(item) {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("/mechanism_status", data).then((res) => {
        console.log(res, "res");
        if (res.status === 1) {
          console.log("4564146");
          this.goreleasedemand(item);
          // if (index === 1) {
          //   console.log(item, "itemmechanism_status");
          //   this.goreleasedemand(item);
          // } else if (index === 2) {
          //   console.log(item, "222");
          //   this.look(item);
          // }
        }
        //  else {
        //   this.$message({
        //     type: "warning",
        //     message: res.msg,
        //   });
        // }
      });
    },

    // 认证
    authentication(item) {
      const index = localStorage.logintype;
      if (index == 2) {
        this.mechanism_status(item);
      } else if (index == 3) {
        this.$message({
          type: "warning",
          message: "该产品只限企业用户和服务机构申请",
        });
      } else {
        let data = {
          token: localStorage.eleToken,
        };
        console.log(index, "index");
        console.log(data, "data");
        this.$post("/select_enterprise_status", data).then((res) => {
          console.log(res, "res");

          if (res.result === 2) {
            this.goreleasedemand(item);
          } else if (res.result === 0 || res.result === 3) {
            // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
            location.href = "https://sso.smeqh.cn/realname/index";
          } else if (res.result === 1) {
            this.$message({
              type: "info",
              message: "您的企业正在认领中，审核通过之后才能进行下一步操作",
            });
          }
        });
      }
    },

    goreleasedemand(item) {
      if (localStorage.eleToken) {
        // releasedemand(){
        // this.releasetext
        let url;
        console.log(item, "item");
        switch (item) {
          case "采购需求":
            url = "/releasepurchase";
            this.$router.push({ path: url, query: { ids: 1, name: item } });
            break;
          case "融资需求":
            url = "/releasefinancing";
            this.$router.push({ path: url, query: { ids: 2, name: item } });
            break;
          case "物流需求":
            url = "/releaselogistics";
            this.$router.push({ path: url, query: { ids: 3, name: item } });
            break;
          case "服务需求":
            url = "/releasedserve";
            this.$router.push({ path: url, query: { ids: 4, name: item } });

            break;
          case "人才需求":
            url = "/releasedtalents";
            this.$router.push({ path: url, query: { ids: 5, name: item } });
            break;
          case "其他需求":
            url = "/releasedorther";
            this.$router.push({ path: url, query: { ids: 6, name: item } });
            break;
        }
      } else {
        this.$message({
          message: "请先登录",
          type: "error",
          duration: 1500,
        });
      }
    },

    getHeight(data) {
      console.log(data, "data");
      this.offsetHeight = data;
    },

    // 分页
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;

      this.marketlist();

      this.selectorder = this.allselectorder.filter((item, index) => {
        return index < page_size;
      });
    },

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      this.marketlist(page);
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allselectorder[i]) {
          tables.push(this.allselectorder[i]);
        }
        this.selectorder = tables;
      }
    },
  },
};
</script>
<style scoped>
.sear_search {
  border-radius: 6px;
  margin-left: 12px;
}

.el-input__inner {
  background-color: #f5f5f5 !important;
  border: none !important;
  border-radius: 25px !important;
}
.sear_search .el-icon-search {
  right: 10px;
  color: #ff7129 !important;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  top: 10px;
  position: relative;
}

.sear_search .el-input__clear {
  height: 100% !important;
}
.laws_tag {
  padding: 3px 12px;

  color: #ff7129;
  background: #ffffff;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}
::v-deep.el-pagination {
  text-align: center;
  padding: 20px 0px;
  background-color: #fff;
}
.productbox {
  width: 272px;
  height: 294px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  margin: 0px 40px 40px 0px;
}
.productbox:hover {
  box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
}

.servehove:hover {
  color: #ff7129;
}
</style>
